.menu-show-enter {
    opacity: 0;
  }
  
  .menu-show-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  
  .menu-show-exit {
    opacity: 1;
  }
  
  .menu-show-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  


  .global-show-enter {
    opacity: 0;
  }
  
  .global-show-enter-active {
    opacity: 1;
    transition: opacity 900ms;
  }
  
  .global-show-exit {
    opacity: 1;

  }
  
  .global-show-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }