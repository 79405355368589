@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,html,#root{
  height: 100%;
  width: 100%;
}

#root > .swiper{
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mySwiper {
  width: 100%;
  height: 100vh;
}

.slide-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  height: 100%;
}


body {
  font-family: 'Roboto', sans-serif; 
}

h1,h2,h3,h4,h5,h6,nav *{
  font-family: 'Gobold';
  font-weight: bold;
}